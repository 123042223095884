<template>
	<div class="error-page">
		<img src="~bh-mod/images/unauthorized.svg" alt="Unauthorized" style="width: 80vw; height: 50vh;">
		<h1>Unauthorized Access</h1>
		<p>Sorry, you are not authorized to view this page.</p>
		<h4>Please contact administrator</h4>
	</div>
</template>

<script>
export default {
	name: 'UnauthorizedPage'
}
</script>

<style scoped>
.error-page {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	height: 90vh;
}

.error-page img {
	max-width: 100%;
	height: auto;
	margin: 20px 0px;
}

.error-page h1 {
	font-size: 36px;
	margin-bottom: 20px;
}

.error-page p {
	font-size: 18px;
	margin-bottom: 20px;
}
</style>
